import { NavLink, useNavigate } from '@remix-run/react';
import classnames from 'classnames';
import { useState } from 'react';
import { SignOut } from '@phosphor-icons/react';
import { links } from '~/global/data/links.navigation';

export interface GlobalNavigationSidebarProps {
  className?: string;
}

export const GlobalNavigationSidebar = (
  _props: GlobalNavigationSidebarProps,
) => {
  // Hooks
  const navigate = useNavigate();
  const [isOpen, _setIsOpen] = useState(false);

  // Setup

  // Handlers
  const onClickToggle = () => {
    navigate('/');
    // setIsOpen(!isOpen);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'border-r border-r-gray-2 bg-gray-1',
        'overflow-hidden transition-all duration-300',
        isOpen ? 'w-[240px]' : 'w-[50px]',
      )}
    >
      <div className="flex h-full flex-col items-center">
        <button
          className="my-2 h-10 w-10"
          onClick={onClickToggle}
          type="button"
        >
          <img alt="" src="/branding/shiftsmart/icon.png" />
        </button>

        <hr className="w-full border-gray-2" />

        {/*
        <NavLink className="p-4" to="/">
          <img alt="" src="/branding/shiftsmart/logo.png" />
        </NavLink>
        */}

        <div className="my-4 flex flex-col gap-4">
          {links.map((link, index) => {
            return (
              <NavLink
                className={(propsLink) => {
                  const { isActive } = propsLink;

                  return classnames(
                    'flex items-center gap-2 transition-colors',
                    {
                      'cursor-default text-brand': isActive,
                      'text-gray-8 hover:text-brand-light': !isActive,
                    },
                  );
                }}
                key={index}
                to={link.to}
              >
                {link.icon}
                {/* <div className="flex-1">{link.children}</div> */}
              </NavLink>
            );
          })}
        </div>

        <div className="flex-1" />

        {/* 🚨 Important 🚨 Note the use of "reloadDocument" below */}
        <NavLink
          className="p-4 text-center"
          reloadDocument={true}
          to="/auth/logout"
        >
          <SignOut className="h-6 w-6 text-gray-8 transition-colors hover:text-brand-light" />
        </NavLink>
      </div>
    </div>
  );
};
