import { NavLinkProps } from '@remix-run/react';
import { HouseLine, User } from '@phosphor-icons/react';

export interface Link extends NavLinkProps {
  children: string;
  icon?: React.ReactElement;
}

export const links: Link[] = [
  {
    children: 'Home',
    icon: <HouseLine className="h-5 w-5" />,
    to: '/',
  },
  {
    children: 'Design',
    icon: <User className="h-5 w-5" />,
    to: '/design',
  },
].map((link) => {
  // Add prefetch to all the links
  return {
    ...link,
    prefetch: 'intent',
  };
});
