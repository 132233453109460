import { ModalProvider } from '@shiftsmartinc/react-hooks';

export interface GlobalContextProps {
  children: React.ReactElement;
}

export const GlobalContext = (props: GlobalContextProps) => {
  const { children } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return <ModalProvider>{children}</ModalProvider>;
};
